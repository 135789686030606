import { Fragment } from 'react';
import {Outlet} from 'react-router-dom';
import Footer from '../footer/footer.component'
import Menu from '../menu/menu.component';
const AdminTemplate =()=>(
    <Fragment>
        <Menu/>
        <Outlet/>
        <Footer/>
    </Fragment>

)
export default AdminTemplate;