import logo from "./logo.svg";
import "./App.css";
import { Routes, Route ,Outlet } from "react-router-dom";
import { Fragment } from "react";
import CreateProduct from "../src/pages/admin/product/create_product.page";
import AdminTemplate  from './components/admin_template/admin_template.template'
import './assets/Styles/global.style.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<AdminTemplate />}>
          <Route path="/" element={<CreateProduct />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
